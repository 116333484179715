import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import { GetBasicUnit, SetBasicUnit, ToBasicUnitMoney, MoneyFormat, UnitFormat } from '@mini-code/base-func/money';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "money"
    }}>{`Money`}</h1>
    <p>{`basic-helper 提供统一的金钱处理方式`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { GetBasicUnit, SetBasicUnit, ToBasicUnitMoney, MoneyFormat, UnitFormat } from 'basic-helper/money';
`}</code></pre>
    <h2 {...{
      "id": "基准单位-basic_unit"
    }}>{`基准单位 BASIC_UNIT`}</h2>
    <p>{`为了统一金额的处理，并且以整数做计算，需要先统一的基准单位 `}<inlineCode parentName="p">{`BASIC_UNIT`}</inlineCode>{`。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const BASIC_UNIT = 1     -> 元
                   10    -> 角
                   100   -> 分
                   1000  -> 厘
                   10000 -> 毫

`}</code></pre>
    <p>{`默认的 `}<inlineCode parentName="p">{`BASIC_UNIT = 10000`}</inlineCode>{`，即 `}<inlineCode parentName="p">{`const count = 1000000`}</inlineCode>{` == `}<inlineCode parentName="p">{`100.0000 元`}</inlineCode></p>
    <h2 {...{
      "id": "设置基准单位"
    }}>{`设置基准单位`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { GetBasicUnit, SetBasicUnit } from 'basic-helper/money';

SetBasicUnit(1); // 基准单位为 "元"
const basicUnit = GetBasicUnit(); // basicUnit = 1
`}</code></pre>
    <h2 {...{
      "id": "金钱格式化"
    }}>{`金钱格式化`}</h2>
    <p>{`上述概念是为了方便计算，但不利于人类阅读，所以需要格式化成人类可读的格式`}</p>
    <ol>
      <li parentName="ol">{`以元为单位`}</li>
      <li parentName="ol">{`加入便于阅读的 , 分隔符`}</li>
      <li parentName="ol">{`精确到指定浮点位数上`}</li>
    </ol>
    <Playground __position={0} __code={'() => {\n  const initMoney = 10000000\n  const [originMoney, setOriginMoney] = useState(initMoney)\n  const [money, setMoney] = useState(MoneyFormat(originMoney))\n  return (\n    <div>\n      <div>原始单位金额: {originMoney}</div>\n      <div>格式化后金额: {money}</div>\n      <hr />\n      <input\n        className=\"form-control\"\n        style={{ width: 300 }}\n        defaultValue={originMoney}\n        onChange={e => {\n          const val = e.target.value\n          setMoney(MoneyFormat(val))\n          setOriginMoney(val)\n        }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      GetBasicUnit,
      SetBasicUnit,
      ToBasicUnitMoney,
      MoneyFormat,
      UnitFormat
    }} mdxType="Playground">
  {() => {
        const initMoney = 10000000;
        const [originMoney, setOriginMoney] = useState(initMoney);
        const [money, setMoney] = useState(MoneyFormat(originMoney));
        return <div>
          <div>原始单位金额: {originMoney}</div>
          <div>格式化后金额: {money}</div>
          <hr />
          <input className="form-control" style={{
            width: 300
          }} defaultValue={originMoney} onChange={e => {
            const val = e.target.value;
            setMoney(MoneyFormat(val));
            setOriginMoney(val);
          }} />
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "反金钱格式化"
    }}>{`反金钱格式化`}</h2>
    <p>{`可以把元为单位的金钱数据转换成基准单位数据`}</p>
    <Playground __position={1} __code={'() => {\n  const initMoney = 10000000\n  const [originMoney, setOriginMoney] = useState(MoneyFormat(initMoney))\n  const [money, setMoney] = useState(ToBasicUnitMoney(originMoney))\n  return (\n    <div>\n      <div>格式化金额: {originMoney}</div>\n      <div>基准单位数据: {money}</div>\n      <hr />\n      <input\n        className=\"form-control\"\n        style={{ width: 300 }}\n        defaultValue={originMoney}\n        onChange={e => {\n          const val = e.target.value\n          setMoney(ToBasicUnitMoney(val))\n          setOriginMoney(val)\n        }}\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      GetBasicUnit,
      SetBasicUnit,
      ToBasicUnitMoney,
      MoneyFormat,
      UnitFormat
    }} mdxType="Playground">
  {() => {
        const initMoney = 10000000;
        const [originMoney, setOriginMoney] = useState(MoneyFormat(initMoney));
        const [money, setMoney] = useState(ToBasicUnitMoney(originMoney));
        return <div>
          <div>格式化金额: {originMoney}</div>
          <div>基准单位数据: {money}</div>
          <hr />
          <input className="form-control" style={{
            width: 300
          }} defaultValue={originMoney} onChange={e => {
            const val = e.target.value;
            setMoney(ToBasicUnitMoney(val));
            setOriginMoney(val);
          }} />
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "单位格式化"
    }}>{`单位格式化`}</h2>
    <p>{`根据中文金钱单位的定义进行格式化`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { UnitFormat } from 'basic-helper';

const result = UnitFormat(1000, 'li'); // result = 1
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      